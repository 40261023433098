import React from 'react'

export default function Home() {
  return (
    <div>
      <h1>
        this is About page
      </h1>
    </div>
  )
}

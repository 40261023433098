import React from 'react'

import './App.css';

export default function Header() {
  return (
    <div>
      <div className='offer '>
          <div className='d-flex justify-content-center'> <i class="fa fa-star" ></i>
            <i class="fa fa-star" ></i>
            <i class="fa fa-star" ></i>
            <i class="fa fa-star" ></i>  
            <i class="fa fa-star" ></i></div> <div>
            <p>1 Million+ Products Sold.</p></div>
            
      
      </div>
    


      
    </div>
  )
}

import React from 'react'

export default function Banner() {
  return (
    <div>
      <section className='hero-banner'>
        <div className='hero-content'>
            <h1>FIND THE PERFECT PRODUCTS</h1>
            <p class="subhead-2"><strong>30% Off</strong> Your First Order + <strong>Free Shipping</strong></p>
            <p class="subhead-3">Use Code : <strong>FIRST30</strong></p>
            
            <div>
            <a href="https://indacloud.co/?utm_source=Spokes_indcloud&utm_medium=PPC&utm_campaign=indacloud" class="btn hero-btn">YES AND I'M OVER 21</a>
            </div>

        </div>
      </section>
    </div>
  )
}

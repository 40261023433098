import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='privacy text-center py-2'>
       <Link to="/Privacy">Privacy Policy</Link>
    </div>
  )
}

export default Footer

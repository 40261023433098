import logo from './assets/img/abc.png';
import Offer from './Offer'
import React from 'react';
import './App.css';
import Banner from './Banner';
import Footer from './Footer';

const Home = () => {
  return (
    <div>
        <Offer/>

        <nav className='text-center py-2'>
     <img src={logo} className='img-fluid text center' alt='abc'/>
     </nav>
      
      <Banner/>
      <Footer/>
    </div>
  )
}

export default Home


import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Home';
import About from './About';
import Privacy from './Privacy'


function App() {
  return (
    <div className="App">



      <BrowserRouter>
     <Routes>  
            <Route path="/" element={<Home />} />
            <Route path="/About" element={<About />} />
            <Route path='/Privacy' element={<Privacy />} />
            
          </Routes>
          </BrowserRouter>
         
    </div>
  );
}

export default App;
